/**************  
VARIABLES
***************/
.site-header.grid-container.fluid {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
.site-header.grid-container.fluid .line {
  border-color: #fff;
}
.site-header.grid-container.fluid .line .site-branding a {
  background: url("/logo-white.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
}
.site-header.grid-container.fluid .line .site-branding a img {
  opacity: 0;
}
.site-header.grid-container.fluid .line .site-navigation .main-navigation .menu li a {
  color: #fff;
}
.site-header.grid-container.fluid .line .site-navigation .main-navigation .menu li a:hover {
  color: #fff;
}
.site-header.grid-container.fluid .line .site-navigation .main-navigation .menu li a:hover:before {
  background: #fff;
}
.site-header.grid-container.fluid .line .site-navigation .main-navigation .menu li.current-menu-item a {
  color: #fff;
}
.site-header.grid-container.fluid .line .site-navigation .main-navigation .menu li.current-menu-item a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #fff;
}
.site-header.grid-container.fluid .burger-section .hamburger .hamburger-inner {
  background: #fff;
}
.site-header.grid-container.fluid .burger-section .hamburger .hamburger-inner:after, .site-header.grid-container.fluid .burger-section .hamburger .hamburger-inner:before {
  background: #fff;
}
.site-header.grid-container.fluid.sticky {
  background: #fff;
}
.site-header.grid-container.fluid.sticky .site-branding a {
  background: none;
}
.site-header.grid-container.fluid.sticky .site-branding a img {
  opacity: 1;
}
.site-header.grid-container.fluid.sticky .site-navigation .main-navigation .menu li a {
  color: #738776;
}
.site-header.grid-container.fluid.sticky .site-navigation .main-navigation .menu li a:hover {
  color: #738776;
}
.site-header.grid-container.fluid.sticky .site-navigation .main-navigation .menu li a:hover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #738776;
}
.site-header.grid-container.fluid.sticky .site-navigation .main-navigation .menu li.current-menu-item a {
  color: #738776;
}
.site-header.grid-container.fluid.sticky .site-navigation .main-navigation .menu li.current-menu-item a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #738776;
}
.site-header.grid-container.fluid.sticky .burger-section .hamburger .hamburger-inner {
  background: #13100D;
}
.site-header.grid-container.fluid.sticky .burger-section .hamburger .hamburger-inner:after, .site-header.grid-container.fluid.sticky .burger-section .hamburger .hamburger-inner:before {
  background: #13100D;
}

.home.section-one.grid-container.fluid {
  position: relative;
  z-index: 0;
  border-bottom-left-radius: 10rem;
  border-bottom-right-radius: 10rem;
  overflow: hidden;
  padding: 0;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-one.grid-container.fluid {
    border-bottom-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
  }
}
.home.section-one.grid-container.fluid .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -o-object-fit: cover;
  object-fit: cover;
}
.home.section-one.grid-container.fluid .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(0, 0, 0, 0.35);
}
.home.section-one.grid-container.fluid .grid-x {
  position: relative;
  min-height: 100vh;
}
.home.section-one.grid-container.fluid .grid-x .txt {
  padding: 10rem;
  position: relative;
  z-index: 1;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-one.grid-container.fluid .grid-x .txt {
    padding: 5rem;
  }
}
.home.section-one.grid-container.fluid .grid-x .txt h1, .home.section-one.grid-container.fluid .grid-x .txt h2, .home.section-one.grid-container.fluid .grid-x .txt h3, .home.section-one.grid-container.fluid .grid-x .txt h4, .home.section-one.grid-container.fluid .grid-x .txt p {
  color: #fff;
}
.home.section-one.grid-container.fluid .grid-x #scroll-down {
  max-height: 10rem;
  position: absolute;
  bottom: 5rem;
  right: 0;
}
.home.section-one.grid-container.fluid .grid-x #scroll-down:hover {
  cursor: pointer;
}
@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .home.section-one.grid-container.fluid .grid-x #scroll-down {
    right: 5rem;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-one.grid-container.fluid .grid-x #scroll-down {
    right: 5rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-one.grid-container.fluid .grid-x #scroll-down {
    right: 2rem;
    max-height: 6rem;
  }
}
@media print, screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
}
@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .home.section-two.grid-container.fluid {
    padding-top: 10rem;
    padding-bottom: 15rem;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-two.grid-container.fluid {
    padding-top: 10rem;
    padding-bottom: 15rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-two.grid-container.fluid {
    padding-bottom: 7.5rem;
  }
}
@media print, screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid .txt {
    padding-left: 5rem;
  }
}
.home.section-two.grid-container.fluid .txt p {
  font-size: 4rem;
  font-weight: 300;
  margin: 0;
}
@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .home.section-two.grid-container.fluid .txt p {
    font-size: 3rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-two.grid-container.fluid .txt p {
    font-size: 2.5rem;
  }
}
.home.section-three.grid-container.fluid {
  background: #738776;
}
@media print, screen and (min-width: 64em) {
  .home.section-three.grid-container.fluid {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
}
@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .home.section-three.grid-container.fluid {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
.home.section-three.grid-container.fluid .cell {
  padding: 3rem;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-three.grid-container.fluid .cell {
    padding: 2rem 0;
  }
}
.home.section-three.grid-container.fluid .cell .item {
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  text-align: center;
  padding: 20rem 5rem;
  position: relative;
  overflow: hidden;
}
@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .home.section-three.grid-container.fluid .cell .item {
    padding: 15rem 5rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-three.grid-container.fluid .cell .item {
    padding: 10rem 1rem;
  }
}
.home.section-three.grid-container.fluid .cell .item img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.home.section-three.grid-container.fluid .cell .item .mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
}
.home.section-three.grid-container.fluid .cell .item .txt {
  position: relative;
}
.home.section-three.grid-container.fluid .cell .item .txt h2 {
  color: #fff;
  margin: 0;
}
.home.section-three.grid-container.fluid .cell .item .txt .button1 {
  background: #fff;
  color: #738776;
}
.home.section-three.grid-container.fluid .cell .item .txt .button1:hover {
  background: none;
  color: #fff;
}
.home.section-four.grid-container.fluid {
  position: relative;
  border-bottom-left-radius: 10rem;
  border-bottom-right-radius: 10rem;
  overflow: hidden;
  padding: 0;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-four.grid-container.fluid {
    border-bottom-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
  }
}
.home.section-four.grid-container.fluid .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -o-object-fit: cover;
  object-fit: cover;
}
.home.section-four.grid-container.fluid .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(0, 0, 0, 0.35);
}
.home.section-four.grid-container.fluid .grid-x {
  position: relative;
  min-height: 100vh;
}
.home.section-four.grid-container.fluid .grid-x .txt {
  padding: 10rem;
  position: relative;
  z-index: 1;
  text-align: center;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-four.grid-container.fluid .grid-x .txt {
    padding: 3rem;
  }
}
.home.section-four.grid-container.fluid .grid-x .txt h1, .home.section-four.grid-container.fluid .grid-x .txt h2, .home.section-four.grid-container.fluid .grid-x .txt h3, .home.section-four.grid-container.fluid .grid-x .txt h4, .home.section-four.grid-container.fluid .grid-x .txt p {
  color: #fff;
}
.home.section-four.grid-container.fluid .grid-x .txt h3 {
  margin-bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .home.section-five.grid-container.fluid {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
}
@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .home.section-five.grid-container.fluid {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-five.grid-container.fluid {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-five.grid-container.fluid {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media print, screen and (min-width: 64em) {
  .home.section-five.grid-container.fluid .txt {
    padding: 0 10rem;
  }
}
@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .home.section-five.grid-container.fluid .txt {
    padding: 0 5rem;
  }
}
.home.section-five.grid-container.fluid .txt p {
  font-size: 2.8rem;
}
@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .home.section-five.grid-container.fluid .txt p {
    font-size: 2.4rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-five.grid-container.fluid .txt p {
    font-size: 1.8rem;
  }
}
.home.section-five.grid-container.fluid .img .image {
  position: relative;
  height: 100%;
  overflow: hidden;
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-five.grid-container.fluid .img .image {
    margin-bottom: 2.5rem;
    height: auto;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-five.grid-container.fluid .img .image {
    margin-bottom: 1rem;
    height: auto;
  }
}
.home.section-five.grid-container.fluid .img .image img {
  height: auto;
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .home.section-five.grid-container.fluid .img .image img {
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
.home.section-five.grid-container.fluid .img .image .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(0, 0, 0, 0.35);
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
.home.section-five.grid-container.fluid .img .image h3 {
  text-align: center;
  color: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  width: 100%;
}
.home.section-five.grid-container.fluid .img .image:hover .mask {
  background: rgba(0, 0, 0, 0);
}
.home.section-six.grid-container.fluid {
  position: relative;
}
@media print, screen and (min-width: 64em) {
  .home.section-six.grid-container.fluid {
    padding: 20rem 0;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-six.grid-container.fluid {
    padding: 10rem 0;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-six.grid-container.fluid {
    padding: 5rem 0;
  }
}
.home.section-six.grid-container.fluid .grid-x {
  max-width: 100%;
  border-bottom-left-radius: 10rem;
  border-bottom-right-radius: 10rem;
  overflow: hidden;
  position: relative;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-six.grid-container.fluid .grid-x {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.home.section-six.grid-container.fluid .grid-x video {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 0 -2rem;
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home.section-six.grid-container.fluid .grid-x video {
    margin: 0 0 -0.5rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-six.grid-container.fluid .grid-x video {
    margin: 0 0 -0.5rem;
  }
}
.home.section-six.grid-container.fluid .grid-x .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(0, 0, 0, 0.35);
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
.home.section-six.grid-container.fluid .grid-x .mask.hide {
  display: none;
}
.home.section-six.grid-container.fluid .grid-x .play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
  text-align: center;
}
.home.section-six.grid-container.fluid .grid-x .play h3 {
  color: #fff;
}
@media print, screen and (max-width: 39.99875em) {
  .home.section-six.grid-container.fluid .grid-x .play svg {
    max-height: 5rem;
  }
}
.home.section-six.grid-container.fluid .grid-x .play.hide {
  display: none;
}